import React, { ReactElement } from "react";
import dynamic from "next/dynamic";

import { GetServerSideProps } from "next";
import WebsiteBuilderSelectWrapper from "@components/layouts/websiteBuilderSelectWrapper";

import TopThree from "@components/shared/topThree";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";

const FAQ = dynamic(() => import("@components/websitebuilderselect/faq"));
const MainListing = dynamic(
    () => import("@components/websitebuilderselect/mainListing"),
);

export default function Home(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <WebsiteBuilderSelectWrapper>
            <div className="text-antivirus-text-black">
                <TopThree
                    title={`The Top 3 Website Builders in ${new Date().getFullYear()}`}
                    listing={domain?.defaultOffers}
                    gradient="blue"
                />
                <MainListing
                    listing={domain?.defaultOffers}
                    comparison={false}
                />
                <FAQ />
            </div>
        </WebsiteBuilderSelectWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
