import React from "react";
import styles from "./topThree.module.scss";
import Badge from "../../antiviruschoice/svg/badge";
import { ListingItem } from "src/lib/shared/types";
import Image from "next/image";
import RightArrow from "@components/svg/rightArrow";
import AffiliateOfferUrl from "../affiliateOfferUrl";
import { affiliateOfferZones } from "@lib/shared/config";
interface Props {
    title?: string | undefined;
    listing?: ListingItem[] | undefined;
    gradient?: "blue" | "gray";
}

const TopThree: React.FC<Props> = ({ title, listing, gradient }) => {
    return (
        <div
            className={`lg:pb-24 flex flex-col justify-center items-center py-4  ${
                gradient === "blue"
                    ? styles["bg-top-gradient-blue"]
                    : styles["bg-top-gradient-gray"]
            }`}
        >
            {title && (
                <h1
                    className={`${styles.header} text-center 
                        text-xl md:text-3xl font-bold mt-4 mb-6 lg:mb-12`}
                >
                    {title}
                </h1>
            )}
            <div
                className={`justify-center items-center w-full flex flex-col lg:flex-row`}
            >
                {listing &&
                    listing.slice(0, 3).map((item, idx) => {
                        return (
                            <div
                                key={idx}
                                className={`flex flex-col mb-3 px-10 py-3 lg:px-0 text-xs justify-around w-full items-center ${styles.offer} h-48 lg:h-52 rounded-md relative lg:w-4/12`}
                            >
                                <div className="w-[150px] h-[60px] relative">
                                    {item?.logo?.fullPath && (
                                        <Image
                                            src={item?.logo?.fullPath}
                                            layout="fill"
                                            alt={item.title}
                                        />
                                    )}
                                </div>
                                <p className="text-center lg:w-4/6">
                                    {item.description}
                                </p>
                                <AffiliateOfferUrl
                                    zone={affiliateOfferZones.HOME_PAGE}
                                    offer={item}
                                    target="_blank"
                                >
                                    <button
                                        className={`rounded-md relative font-bold flex items-center ${styles.button} px-5 py-3
                                        text-sm h-10`}
                                    >
                                        Get it Now
                                        <RightArrow className="w-4 ml-2" />
                                    </button>
                                </AffiliateOfferUrl>

                                <div
                                    className={`hidden ${styles.badge} ${
                                        idx === 0 ? "lg:block" : ""
                                    }`}
                                >
                                    <Badge />
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};
export default TopThree;
