import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import styles from "./header.module.scss";

export default function Header({
    useSecondaryLogo,
}: {
    useSecondaryLogo?: boolean;
}): ReactElement {
    const { domain } = useDomainContext();

    return (
        <header className={styles.header}>
            <div className="inner max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
                <div className="logo w-72 lg:w-80">
                    <a href="/" className="w-full h-auto mx-auto lg:ml-0">
                        <img
                            src={
                                useSecondaryLogo
                                    ? domain?.secondaryLogo?.fullPath ??
                                      "./logo.svg"
                                    : domain?.logo?.fullPath ?? "./logo.svg"
                            }
                            width="500px"
                            height="28px"
                            alt="websitebuilderselect logo"
                        />
                    </a>
                </div>
            </div>
        </header>
    );
}
